import React from "react";
import Page from "../../components/Layout/Page";
import pageColors from "../../data/pageColors";
import { WEBCOMIC } from "../../routes";
import { graphql } from "gatsby";
import sortBy from "lodash.sortby";
import { Query } from "../../index";

export default ({ data }: { data: Query }) => {
    const contributors = sortBy(
        data.allWebcomicSuggestion.group,
        (group) => group.totalCount * -1,
    );

    return (
        <Page
            to={WEBCOMIC}
            title={"Webcomic Statistics"}
            layoutClassName={`bg-${pageColors.webcomic}-900 font-sans text-white`}
            className={`p-10`}
        >
            <h1 className={"text-3xl"}>Statistics</h1>
            <h2 className={"mt-10 text-xl"}>Contributors</h2>
            <ul>
                {contributors.map((group) => (
                    <li>
                        <strong>{group.fieldValue}</strong>: {group.totalCount}{" "}
                        suggestions
                    </li>
                ))}
            </ul>
        </Page>
    );
};

export const query = graphql`
    {
        allWebcomicSuggestion {
            group(field: author) {
                field
                fieldValue
                totalCount
            }
        }
    }
`;
